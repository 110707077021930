import React from 'react'
import { View, StyleSheet, Text, Pressable } from 'react-native'

interface CustomCheckboxProps {
  isChecked: boolean
  onChange: () => void
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  isChecked,
  onChange,
}) => {
  return (
    <Pressable style={styles.checkboxContainer} onPress={onChange}>
      <View style={[styles.checkbox, isChecked && styles.checkedCheckbox]}>
        {isChecked && <Text style={styles.checkmark}>✔</Text>}
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  checkboxContainer: {
    borderWidth: 1,
    borderColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    width: 12,
    height: 12,
  },
  checkbox: {
    width: 12,
    height: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkedCheckbox: {
    backgroundColor: 'blue',
    borderColor: 'blue',
  },
  checkmark: {
    color: 'white',
    fontSize: 10,
    fontWeight: 'bold',
  },
})

export default CustomCheckbox
