import React, { FunctionComponent, createRef, useEffect, useState } from 'react'
import {
  Image,
  KeyboardAvoidingView,
  ScrollView,
  View,
  Text,
  StyleSheet,
  Linking,
} from 'react-native'
import Colors from '../constants/Colors'
import originalSrc from '../variants/original/logo.png'
import mirrorSrcfrom from '../variants/mirror/logo.png'
import { isMirror } from '../constants/Config'

export interface ISuccessRegistrationProps {
  userName: string
}

export const SuccessRegistrationComponent: FunctionComponent<
  ISuccessRegistrationProps
> = (props) => {
  const imgSrc = isMirror ? mirrorSrcfrom : originalSrc

  const isEnvMirror = process.env.IS_MIRROR
  const buildName = process.env.BUILD_NAME || (isEnvMirror ? 'Mirror' : 'Scope')

  return (
    <View style={styles.container}>
      <View style={styles.mainBody}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            flex: 1,
            marginTop: 25,
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <View>
            <KeyboardAvoidingView enabled>
              <View style={styles.imageWrapper}>
                <Image source={{ uri: imgSrc }} style={styles.image}></Image>
              </View>
              <Text style={styles.title}>Sikeres Regisztráció!</Text>
              <Text style={styles.description}>
                Az applikációhoz tartozó telepítési linket és a belépéshez
                szükséges aktiváló kódot emailben továbbítjuk 24 órán belül!
              </Text>
              <Text style={styles.description}>
                Kérdés esetén írj nekünk az{' '}
                <Text
                  style={styles.link}
                  onPress={() =>
                    Linking.openURL('mailto:info@mirrortheweb.com')
                  }
                >
                  info@mirrortheweb.com
                </Text>{' '}
                címre!
              </Text>
              <Text style={styles.text}>Üdvözlettel,</Text>
              <Text style={styles.text}>a {buildName} csapata</Text>
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default SuccessRegistrationComponent
const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.listWhite,
  },
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: Colors.white,
    maxWidth: 700,
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
  },
  description: {
    color: Colors.oxfordBlue,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
    fontFamily: 'Roboto_400Regular',
  },
  title: {
    fontSize: 24,
    color: Colors.oxfordBlue,
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 25,
    fontFamily: 'Roboto_500Medium',
  },
  imageWrapper: { alignSelf: 'center', marginBottom: 75 },
  image: {
    height: 200,
    width: 200,
  },
  text: {
    fontSize: 16,
    marginBottom: 5,
    fontFamily: 'Roboto_400Regular',
  },
  link: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
})
