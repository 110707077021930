import React, { FunctionComponent, createRef, useEffect, useState } from 'react'
import {
  Keyboard,
  Image,
  KeyboardAvoidingView,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  Linking,
} from 'react-native'
import { checkRegistrationToken, registration } from '../api/user/user.query'
import Colors from '../constants/Colors'
import originalSrc from '../variants/original/logo.png'
import mirrorSrcfrom from '../variants/mirror/logo.png'
import { isMirror } from '../constants/Config'
import { extractParamFromUrl, getHostFromUrl } from '../utils/UrlHandler'
import CustomCheckbox from './CustomCheckboxComponent'
import LinkText from './LinkTextComponent'
import SuccessRegistrationComponent from './SuccessRegistrationComponent'
import InvalidRegistrationTokenComponent from './InvalidRegistrationTokenComponent'
import { Spinner } from './Spinner'

export interface IRegistrationProps {
  url: string
}

export const RegistrationComponent: FunctionComponent<IRegistrationProps> = (
  props
) => {
  const { url } = props
  const token = extractParamFromUrl(url, 't')

  const nameInputRef = createRef<TextInput>()
  const phoneInputRef = createRef<TextInput>()
  const emailInputRef = createRef<TextInput>()

  const [formState, setFormState] = useState({
    Email: '',
    PhoneNumber: '',
    Name: '',
    error: '',
    Token: token,
  })

  const imgSrc = isMirror ? mirrorSrcfrom : originalSrc
  const [onFocusEmail, setOnFocusEmail] = useState(false)
  const [onFocusName, setOnFocusName] = useState(false)
  const [onFocusPhone, setOnFocusPhone] = useState(false)
  const [isTermsRead, setIsTermsRead] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [successRegistration, setSuccessRegistration] = useState<boolean>(false)
  const [isAcceptedTerms, setAccepterTerms] = useState<boolean>(false)
  const [isValidToken, setValidToken] = useState<boolean>(true)
  const [isLoading, setLoading] = useState<boolean>(true)

  const buildName = process.env.BUILD_NAME || (isMirror ? 'Mirror' : 'Scope')

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await checkRegistrationToken(token)

        setValidToken(response?.isValidToken)
        setLoading(false)
      } catch (error) {
        setValidToken(false)
      }
    }

    checkToken()
  }, [url])

  const handleCheckboxChange = () => {
    setIsTermsRead(!isTermsRead)
  }

  const openTerms = async () => {
    var baseUrl = getHostFromUrl(url)

    return await Linking.openURL(`http://${baseUrl}/terms?t=${token}`)
  }

  const handleRegistration = async () => {
    const { Email, PhoneNumber, Name, Token, error } = formState

    try {
      setError('')
      if (!Name) {
        setFormState({
          ...formState,
          error: 'A név megadása kötelező!',
        })
      } else if (!Email) {
        setFormState({
          ...formState,
          error: 'Az email cím megadása kötelező!',
        })
      } else if (!PhoneNumber) {
        setFormState({
          ...formState,
          error: 'A telefonszám megadása kötelező!',
        })
      } else if (!isTermsRead) {
        setFormState({
          ...formState,
          error: 'Az ÁSZF elfogadása kötelező!',
        })
      } else if (PhoneNumber) {
        const phoneNumberPattern = /^36\d{9}$/
        if (!phoneNumberPattern.test(PhoneNumber)) {
          setFormState({
            ...formState,
            error: 'Hibás a telefonszám formátuma!',
          })
        } else {
          setFormState({
            ...formState,
            error: '',
          })
        }
      }
      if (isTermsRead && Name && Email && PhoneNumber && Token && !error) {
        await registration({
          Email,
          PhoneNumber,
          Name,
          Token,
          IsTermsRead: isTermsRead,
        })
        setSuccessRegistration(true)
        setError('')
      }
    } catch (error: any) {
      setError(error.message)
      setSuccessRegistration(false)
    }
  }

  if (isLoading) {
    return (
      <View style={styles.container}>
        <View style={styles.mainBody}>
          <Spinner isLoading={isLoading}></Spinner>
        </View>
      </View>
    )
  }

  if (!isValidToken) {
    return (
      <View style={styles.container}>
        <View style={styles.mainBody}>
          <InvalidRegistrationTokenComponent />
        </View>
      </View>
    )
  }

  if (successRegistration)
    return (
      <View style={styles.container}>
        <View style={styles.mainBody}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
              flex: 1,
              marginTop: 25,
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <SuccessRegistrationComponent userName={formState.Name} />
          </ScrollView>
        </View>
      </View>
    )

  return (
    <View style={styles.container}>
      <View style={styles.mainBody}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            marginTop: 25,
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <View>
            <KeyboardAvoidingView enabled>
              <View style={styles.imageWrapper}>
                <Image source={{ uri: imgSrc }} style={styles.image}></Image>
              </View>
              <Text style={styles.subTitle}>Ismerd meg a {buildName}-t</Text>
              <Text style={styles.description}>
                A {buildName} a legfrissebb hírek forrása, személyre szabott
                tartalmakkal és azonnali értesítésekkel. Bönészd a híreket több
                forrásból, offline módban is!
              </Text>
              <View style={styles.horizontalLine} />
              <Text style={styles.title}>Regisztráció</Text>
              <View style={styles.sectionStyle}>
                <TextInput
                  style={[
                    styles.inputStyle,
                    onFocusName ? styles.onFocus : styles.onBlur,
                  ]}
                  onChangeText={(Name) =>
                    setFormState({
                      ...formState,
                      Name,
                    })
                  }
                  placeholder="Név"
                  placeholderTextColor={Colors.oxfordBlue + 85}
                  keyboardType="default"
                  ref={nameInputRef}
                  onSubmitEditing={Keyboard.dismiss}
                  onFocus={() => {
                    setOnFocusName(true)
                  }}
                  onBlur={() => {
                    setOnFocusName(false)
                  }}
                  blurOnSubmit={false}
                  underlineColorAndroid="transparent"
                  returnKeyType="next"
                />
              </View>
              <View style={styles.sectionStyle}>
                <TextInput
                  style={[
                    styles.inputStyle,
                    onFocusEmail ? styles.onFocus : styles.onBlur,
                  ]}
                  onChangeText={(Email) =>
                    setFormState({
                      ...formState,
                      Email,
                    })
                  }
                  placeholder="Email cím"
                  placeholderTextColor={Colors.oxfordBlue + 85}
                  autoCapitalize="none"
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  returnKeyType="next"
                  underlineColorAndroid="transparent"
                  onSubmitEditing={() =>
                    emailInputRef.current && emailInputRef.current.focus()
                  }
                  blurOnSubmit={false}
                  onFocus={() => {
                    setOnFocusEmail(true)
                  }}
                  onBlur={() => {
                    setOnFocusEmail(false)
                  }}
                />
              </View>
              <View style={styles.sectionStyle}>
                <TextInput
                  style={[
                    styles.inputStyle,
                    onFocusPhone ? styles.onFocus : styles.onBlur,
                  ]}
                  onChangeText={(PhoneNumber) =>
                    setFormState({
                      ...formState,
                      PhoneNumber,
                    })
                  }
                  placeholder="Tel (36XXXXXXXXX)"
                  placeholderTextColor={Colors.oxfordBlue + 85}
                  keyboardType="default"
                  ref={phoneInputRef}
                  onSubmitEditing={Keyboard.dismiss}
                  onFocus={() => {
                    setOnFocusPhone(true)
                  }}
                  onBlur={() => {
                    setOnFocusPhone(false)
                  }}
                  blurOnSubmit={false}
                  secureTextEntry={false}
                  underlineColorAndroid="transparent"
                  returnKeyType="next"
                />
              </View>

              {formState.error && (
                <Text style={styles.errorTextStyle}>{formState.error}</Text>
              )}
              {error && <Text style={styles.errorTextStyle}>{error}</Text>}
              <View style={styles.checkBox}>
                <CustomCheckbox
                  isChecked={isTermsRead}
                  onChange={handleCheckboxChange}
                />
                <Text>Elfogadom az</Text>
                <LinkText
                  onPress={() => openTerms()}
                  linkText={'Általános Szerződési Feltételeket'}
                />
              </View>
              <TouchableOpacity
                style={styles.buttonStyle}
                activeOpacity={0.5}
                onPress={async () => handleRegistration()}
              >
                <Text style={styles.buttonTextStyle}>Regisztráció</Text>
              </TouchableOpacity>
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default RegistrationComponent
const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.listWhite,
  },
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: Colors.white,
    maxWidth: 700,
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
  },
  description: {
    color: Colors.oxfordBlue,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 25,
    fontFamily: 'Roboto_400Regular',
  },
  title: {
    fontSize: 24,
    color: Colors.oxfordBlue,
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 25,
    fontFamily: 'Roboto_500Medium',
  },
  subTitle: {
    fontSize: 16,
    color: Colors.oxfordBlue,
    textAlign: 'center',
    fontFamily: 'Roboto_500Medium',
  },
  sectionStyle: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: '60%',
    margin: 13,
  },
  horizontalLine: {
    height: 1,
    backgroundColor: Colors.oxfordBlue + 90,
    width: '100%',
    alignSelf: 'center',
  },
  buttonStyle: {
    width: 170,
    alignSelf: 'center',
    backgroundColor: Colors.oxfordBlue,
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: Colors.orangeWeb,
    fontFamily: 'Roboto_500Medium',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    height: 33,
    color: Colors.oxfordBlue,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 100,
    outlineStyle: 'none',
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
    width: '80vw',
    alignSelf: 'center',
    marginHorizontal: 13,
  },
  onFocus: { borderWidth: 2, borderColor: Colors.oxfordBlue },
  onBlur: { borderWidth: 1, borderColor: Colors.oxfordBlue },
  imageWrapper: { alignSelf: 'center', marginBottom: 75 },
  image: {
    height: 200,
    width: 200,
  },
  checkBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    flexWrap: 'wrap',
  },
})
